import { useEffect, useState } from "react";
import { IGInput } from "../../ignitor-react";
import { validateEmail } from "../../constants";
import "./Contact.css";
import $cs from "../../services";

const Contact = (props) => {
  const [payload, setPayload] = useState({
    full_name: "",
    email: "",
    mobile: "",
    description: "",
    location: "",
  });
  const [error, setError] = useState({});
  const [stage, setStage] = useState('FORM');

  // const dispatch = useDispatch();
  // const valueSelector = useSelector();
  // const [isLoading, setLoading] = useState(true);

  // useEffect(() => {
  // });
  const validate = () => {
    let proceed = true;
    let errorObj = {};
    if (!payload.email || payload.email === "") {
      errorObj.email = "Email Address is required";
      proceed = false;
    }
    if (payload.email && !validateEmail(payload.email)) {
      errorObj.email = "Enter valid Email Address";
      proceed = false;
    }
    if (!payload.full_name || payload.full_name === "") {
      errorObj.full_name = "Full Name is required";
      proceed = false;
    }
    if (payload.full_name && payload.full_name.length < 3) {
      errorObj.full_name = "Full Name should be min 3 characters";
      proceed = false;
    }
    if (payload.mobile && payload.mobile.indexOf("+") === -1) {
      errorObj.mobile = "Enter valid Phone Number";
      proceed = false;
    }
    if (payload.mobile && payload.mobile.length < 7) {
      errorObj.mobile = "Enter valid Phone Number";
      proceed = false;
    }
    if (!payload.description || payload.description === "") {
      errorObj.description = "Description is required";
      proceed = false;
    }
    if (payload.description && payload.description.length < 3) {
      errorObj.description = "Description should be min 3 characters";
      proceed = false;
    }
    setError(errorObj);
    console.log(error.description);
    return proceed;
  };
  const contactUs = () => {
    if (validate()) {
      $cs.contact.create({
        data: payload
      }).then(res =>{
        // alert("done");
        setStage('DONE')
      }, err=>{

      })
      // return (
      //   <div>
      //     <Card>Thanks for contacting, our team will reach out to you.</Card>
      //   </div>
      // );
    }
  };

  return (
    <section id="contact-page">
      <div className="page-banner">
        <div className="inner">
          <h2 className="page-name">Contact Us</h2>
          <img src="/index-block-top.png" alt="shape" className="img-fluid" />
        </div>
        <span className="slider-shape"></span>
      </div>
      <div className="quick-info">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-l2">
              <ul>
                <li>
                  <div className="each">
                    <div className="icon">
                      <img src="/email-color.svg" alt="" />
                    </div>
                    <div className="text">
                      <h4>Reach Us</h4>
                      <a href="mailto:info@goteacherpro.com">
                        info@goteacherpro.com
                      </a>
                    </div>
                  </div>
                </li>
                {/* <li>
                  <div className="each">
                    <div className="icon">
                      <img src="/call.svg" alt="" />
                    </div>
                    <div className="text">
                      <h4>Call Us</h4>
                      <a href="tell:+918985948415">+91 8985948415</a>
                    </div>
                  </div>
                </li> */}
                <li>
                  <div className="each">
                    <div className="icon">
                      <img src="/address.svg" alt="" />
                    </div>
                    <div className="text">
                      <h4>Address</h4>
                      <p>
                        Nallacheruvu, Guntur, <br /> Andhra Pradesh, India,
                        522003.
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-form">
        <div className="form">
          {
            stage==='FORM'?(
            <div className="form-group">
              <IGInput
                label="Full Name"
                isRequired={true}
                placeholder="Full Name"
                value={payload.full_name}
                error={error.full_name}
                setValue={(val) => {
                  setPayload({
                    ...payload,
                    full_name: val,
                  });
                }}
              />
              <IGInput
                type="email"
                maxLength={100}
                isRequired={true}
                label="Email Address"
                placeholder="Email Address"
                value={payload.email}
                error={error.email}
                setValue={(val) => {
                  setPayload({
                    ...payload,
                    email: val?val.toLowerCase():val,
                  });
                }}
              />
              <IGInput
                type="phone"
                label="Phone Number"
                placeholder="Phone Number"
                value={payload.mobile}
                error={error.mobile}
                setValue={(val) => {
                  setPayload({
                    ...payload,
                    mobile: val,
                  });
                }}
              />
              <IGInput
                type="location"
                label="Location"
                placeholder="Location"
                value={payload.location}
                error={error.location}
                setValue={(val) => {
                  setPayload({
                    ...payload,
                    location: val,
                  });
                }}
              />
              <div>
                <label htmlFor="exampleFormControlTextarea1">Message</label>
                <textarea
                  className={`form-control ig-form-input ${
                    error?.description ? " ig-border-error" : ""
                  }`}
                  id="exampleFormControlTextarea1"
                  rows="3"
                  placeholder="Leave us a message..."
                  value={payload.description}
                  onChange={(e) =>
                    setPayload({
                      ...payload,
                      description: e.target.value,
                    })
                  }
                  required
                ></textarea>
              </div>
              <div className="pad-top-35 text-center">
                <button
                  onClick={contactUs}
                  className="btn btn-yellow w-100 btn-size-theme"
                >
                  Send Message
                </button>
              </div>
            </div>
            ):(
              <div className="row margin-0 justify-content-center">
                <div className="col pad-15 text-center">
                  <img src="/check-orange.png" alt="" />
                  <h5 className="txt-theme pad-10">Your request has been sent.</h5>
                </div>
              </div>
            )
          }
        </div>
      </div>
    </section>
  );
};

export default Contact;
